<template>
  <div class=" h-100-vh">
    <p class="text-right">
      <button class="btn btn-success rounded-pill" v-text=" $t('general.button.create') "
          @click="setModalPickup(true)">
        </button>
    </p>
    <div v-show=" pickupsList.length > 0">
      <!-- Mobile -->
      <div class=" d-md-none ">
        <table class="table ecart-table table-striped ">
          <thead>
            <tr>
              <th class="text-left" v-text="$t('dashboard.menu.pickups')"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for=" (pickup , index ) in pickupsList " :key=" index">
              <td>
                <div class="row">
                  <div class="col-12">
                    <p class="text-truncate">
                      <strong v-text="$t('general.form.number') + ': ' "></strong>
                      <router-link :to="'/dashboard/pickups/' + pickup.id ">
                        <strong v-text=" pickup.pickup_number "> </strong>
                      </router-link>
                    </p>
                  </div>

                  <div class="col-12">
                    <p class="text-truncate">
                      <strong class="f-w-500" v-text=" $t('tables.carrier') + ': ' "></strong>
                      <span class="text-uppercase" v-text=" pickup.shipment.carrier"></span>
                    </p>
                  </div>

                  <div class="col-6">
                    <p>
                      <strong class="f-w-500" v-text=" $t('tables.packages') + ': ' "></strong>
                      <span v-text=" pickup.shipment.pickup.total_packages "></span>
                    </p>
                  </div>
                  <div class="col-6 text-right">
                    <p>
                      <strong class="f-w-500" v-text=" $t('tables.date') + ': ' "></strong>
                      <span v-text=" fnDate( pickup.shipment.pickup.date, CONST.DATE_FORMATS.DD_MM_YYYY ) "></span>
                    </p>
                  </div>

                  <div class="col-12 ">
                    <hr>
                    <p class="text-right">
                      <strong class="f-w-500" v-text=" $t('tables.pickup') + ': ' "></strong>
                      <span
                        v-text=" pickup.shipment.pickup.time_from + ':00 - ' + pickup.shipment.pickup.time_to + ':00' "></span>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Desktop -->
      <table class="table ecart-table table-striped text-center d-none d-md-table ">
        <thead>
          <tr>
            <th class="text-left" v-text="$t('general.form.number')"></th>
            <th v-text="$t('tables.carrier')"></th>
            <th v-text="$t('tables.packages')"></th>
            <th v-text="$t('tables.date')"></th>
            <th v-text="$t('general.schedule')"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" (pickup , index ) in pickupsList " :key=" index">
            <td class="text-left text-uppercase">
              <router-link :to="'/dashboard/pickups/' + pickup.id ">
                <p> <strong v-text=" pickup.pickup_number "> </strong> </p>
              </router-link>
            </td>
            <td>
              <p class="text-uppercase" v-text="pickup.shipment.carrier"></p>
            </td>
            <td>
              <p v-text=" pickup.shipment.pickup.total_packages "></p>
            </td>
            <td>
              <p v-text=" fnDate( pickup.shipment.pickup.date, CONST.DATE_FORMATS.DD_MM_YYYY )"></p>
            </td>
            <td>
              <p>
                <span
                  v-text="pickup.shipment.pickup.time_from + ':00 - ' + pickup.shipment.pickup.time_to + ':00' "></span>
              </p>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <no-data :dataMessage="$t('noDataMessages.pickupsList')" v-show=" pickupsList.length == 0" :isLoading="loading.data"
      :showBtn="false"></no-data>
    <PickupsCreate ref="pickupModal" />
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  import PickupsCreate from './components/pickups-create-modal';


  export default {
    components: {
      PickupsCreate,
    },
    data() {
      return ({})
    },
    computed: {
      // ...mapGetters('pickups', ['']),
      ...mapState('pickups', ['loading', 'pickupsList']),
    },
    methods: {
      ...mapMutations('pickups', ['setModalPickup']),
      ...mapActions('pickups', ['fnApiGetPickups', 'fnApiGetStores', 'fnApiGetCarriersList',
        'fnApiGetOrderInformaiton'
      ]),
    },
    async mounted() {
      this.fnApiGetPickups();
      await this.fnApiGetCarriersList();
      await this.fnApiGetStores();

      if (this.$route.query.order_id != null) {
        await this.fnApiGetOrderInformaiton(this.$route.query.order_id);
        this.$refs.pickupModal.fnChangeCarrier();
      }
    }
  }
</script>